import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import VueCookies from "vue-cookies"

Vue.use(Router)

// Router.afterEach((to, from) => {
//   console.log("after the rain")
// })

var saveRoute = function beforeEnter(to, from, next) {
  console.log("before the rain")
  next()
}

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  beforeEach(to, from, next) { saveRoute(to, from, next) },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeRouteLeave(to, from, next) { saveRoute(to, from, next) },
      afterEach(to, from, next) { saveRoute(to, from, next) },
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    // {
    //   path: '/manual',
    //   name: 'manual',
    //   component: () => import('./views/Manual.vue')
    // },
    {
      path: '/recipe',
      name: 'recipe',
      props: (route) => ({ url: route.query.url, text: route.query.text, portions: route.query.portions }),
      // meta: { gtm: '/recipe' },
      // meta: (route) => ({ gtm: route.query.url ? "/recipe-url" : "/recipe-text" }),
      component: () => import('./views/Recipe.vue')
    }
  ]
})

export default router

router.beforeEach((to, from, next) => {
  // console.log("beofre test", from.name)
  VueCookies.set('prevRoute', from.name)
  next()
})

// router.afterEach((to, from) => {
//   console.log("after test")
// })