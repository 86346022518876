import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGtm from 'vue-gtm';

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})


// router.beforeEach((to, from, next) => {
//   const IsItABackButton = window.popStateDetected
//   window.popStateDetected = false
//   if (IsItABackButton && from.meta.someLogica) {
//     next(false) 
//     return ''
//   }
//   next()
// })

Vue.use(VueGtm, {
  id: 'GTM-P693KRD', // Your GTM ID
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ['recipe'] // If router, you can exclude some routes name (case insensitive) (optional)
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
