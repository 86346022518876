<template>
<div class='textarea-placeholder'>
    <textarea v-model="content" @input="handleInput" @keydown="resizeInput" v-bind:style="{ height: textareaHeight }" autofocus></textarea>
    <div v-html="placeholder" :class="{'has-content' : content}">
    </div>
</div>
</template>

<style lang="scss" scoped>

@import '../assets/css/variables.scss';

/*************** Solid 1px troke below each line *********************/
$textarea_lineheight: 35px;
$textarea_lineheight_min_1: 34px;
$line_color: #B2DAAD;

textarea {
    color: white;
    border: 0px;
    display: block;
    line-height: $textarea_lineheight;
    margin: 0;
    padding: 0;
    margin-bottom: 1em;
    resize: none;
    width: 100%;
    min-height: 100px;
    height: 20em;
    overflow: hidden;

    /* no scollbar */
    border-radius: 0;
    background-color: transparent;
    background-image: none;
    outline: none;

    /* Visible lines */
    background-position-y: -4px;
    background-image: -moz-linear-gradient( top, transparent, transparent $textarea_lineheight_min_1, $line_color 0px);
    background-image: -webkit-linear-gradient( top, transparent, transparent $textarea_lineheight_min_1, $line_color 0px);
    background-image: -ms-linear-gradient(to bottom, $main_color, $main_color $textarea_lineheight_min_1, $line_color 0px);
    background-image: linear-gradient(to bottom, $main_color, $main_color $textarea_lineheight_min_1, $line_color 0px);
    background-size: 100% $textarea_lineheight;
    -webkit-background-size: 100% $textarea_lineheight;
    background-attachment: local;
}

textarea::placeholder {
  color:  rgba(255, 255, 255, 0.8);
}

textarea[data-placeholder].active {
  color: rgba(255, 255, 255, 0.8);
}
/*************** Solid 1px troke below each line *********************/


/*************** Multiline Placeholder Hack *********************/

@mixin placeholder-style() {
  color: rgba(255,255,255,0.8);
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

textarea {
  display: block;
  width: 100%;
}

.textarea-placeholder {
  position: relative;
  > div {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: $textarea_lineheight;
    @include placeholder-style();

    &.has-content {
      display: none;
    }
  }
}

::-webkit-input-placeholder {
  @include placeholder-style();
}
:-moz-placeholder {
  @include placeholder-style();
}
::-moz-placeholder {
  @include placeholder-style();
}
:-ms-input-placeholder {
  @include placeholder-style();
}
/*************** Multiline Placeholder Hack *********************/
</style>

<script>

export default {
  props: ["placeholder", "value"],
  data() {
      return {
          content: this.value,
          textareaHeight: 200
      }
  },
  // beforeMount() {
  //   console.log("w" + window.innerWidth)
  //   // if(window.innerWidth > 860) {
  //   //   this.textareaHeight = 180
  //   // } else {
  //   //   this.textareaHeight = 280
  //   // }
  // },
  methods: {
    handleInput (e) {
      this.$emit('input', this.content)
    },
    resizeInput (e) {
      this.textareaHeight = e.target.scrollHeight + "px"
      console.log("changed " + this.textareaHeight)
    }
  }
}

</script>