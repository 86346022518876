<template>
  <div class="manual">

    <div id="main-column" class="standard-side-margins">
      <h1>Klimatkolla recept</h1>
      <!-- Mata in ett recept för hand i följande format:<br> 
      Titel (t.ex Tomatsås och pasta)<br>
      Andra raden Antal portioner (t.ex 4 portioner) följt av:<br>
      Mängd ingrediens med en ingrediens per rad ("4 ägg" eller "4 tsk socker").<br><br> -->
      <div v-if="mode == 'portions'">
        <h2>Hur många är receptet för?</h2>
        <v-select v-model="portion" :options="portions_options" :searchable=false class="portions-select"></v-select>
      </div>
      <!-- Klistra in en receptlänk från coop.se, ica.se, mittkok.expressen.se eller koket.se. Du kan också fylla i ett r -->
      <Textarea v-if="mode == 'recipe'" v-model="text" placeholder="Fyll i ett recept själv med en ingrediens per rad.<br><br>Exempel:<br>2 dl mjölk<br>400 gram kyckling<br>1/2 lök" />
      <!-- <p class="observe-info-text">Observera att ditt recept lagras för efteranalys.</p> -->
      <p v-if="show_empty_input_error">
        Fyll i en länk eller ett recept på de streckade linjerna ovanför innan du trycker "Analysera".
      </p>
      <div id="analyze-button" @click="next_step()" class="button submit-form-button">Analysera <img src="../assets/search-icon.svg" id="search-icon" width=22 height=23></div>
    </div>

    <div id="image-column" @click="goto_about()">
      <img src="../assets/read-more-above.svg" id="read-more-bubble-above">
      <img src="../assets/read-more-side.svg" id="read-more-bubble-side" class="standard-left-margin">
      <div class="polar-bear-container">
        <img src="../assets/polar-bear.svg" id="polar-bear-image">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../assets/css/variables.scss';

#read-more-bubble-above {
  display: none;
}

#polar-bear-image {
  display: block;
}

#image-column {
  cursor: pointer;
}


@media #{$handheld} {
  #image-column {
    display: flex;
  }

  #read-more-bubble-side {
    width: 130px;
    align-self: flex-start;
    margin-right: -20px;
  }

  .polar-bear-container {
    flex-grow: 1;
  }

  #polar-bear-image {
    margin-top: 3em;
  }
}

@media #{$tablet-and-above} {
  #read-more-bubble-above {
    width: 150px;
    display: block;
  }

  #read-more-bubble-side {
    display: none;
  }
}

.manual {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#main-column {
  flex-grow: 1;
}

@media #{$tablet-and-above} {
  .manual {
    flex-direction: row;
  }

  #main-column {
    margin-right: 50px;
  }

  #image-column {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

h1 {
  font-size: 55px;
  text-align: left;
}

h2 {
  text-align: left;
}

@media #{$handheld} {
  #image-column {
    margin-top: 1em;
  }

  h1 {
    font-size: 35px;
  }

}

.observe-info-text {
  margin-bottom: 2em;
}

#analyze-button {
  line-height: 1.8em;
  width: 100%;
  box-sizing: border-box;
}

#search-icon {
  position: relative;
  top: 4px;
  margin-left: 4px;
}

// $textarea_lineheight: 35px;
// $textarea_lineheight_min_1: 34px;
// $line_color: #B2DAAD;
// textarea {
//     color: white;
//     border: 0px;
//     display: block;
//     line-height: $textarea_lineheight;
//     margin: 0;
//     padding: 0;
//     margin-bottom: 1em;
//     resize: none;
//     width: 100%;
//     min-height: 100px;
//     height: 20em;
//     overflow: hidden;

//     /* no scollbar */
//     border-radius: 0;
//     background-color: transparent;
//     background-image: none;
//     outline: none;

//     /* Visible lines */
//     background-position-y: -4px;
//     background-image: -moz-linear-gradient( top, transparent, transparent $textarea_lineheight_min_1, $line_color 0px);
//     background-image: -webkit-linear-gradient( top, transparent, transparent $textarea_lineheight_min_1, $line_color 0px);
//     background-image: -ms-linear-gradient(to bottom, $main_color, $main_color $textarea_lineheight_min_1, $line_color 0px);
//     background-image: linear-gradient(to bottom, $main_color, $main_color $textarea_lineheight_min_1, $line_color 0px);
//     background-size: 100% $textarea_lineheight;
//     -webkit-background-size: 100% $textarea_lineheight;
//     background-attachment: local;
// }

// textarea::placeholder {
//   color:  rgba(255, 255, 255, 0.8);
// }

// textarea[data-placeholder].active {
//   color: rgba(255, 255, 255, 0.8);
// }


.portions-select {
  margin-top: 2em;
}

@media #{ $handheld } {
  .portions-select {
    margin-top: 1em;
  }
}

.submit-form-button {
  margin-bottom: 1em;
  display:  block;

  @media #{ $handheld } {
    width: auto;
  }
}
</style>

<script>
import VSelect from 'vue-select'
import Api from '@/utils/Api.js'
import Textarea from '@/components/Textarea.vue';
import VueCookies from "vue-cookies"

// window.$ = require('jquery')
// window.JQuery = require('jquery')

// let MultilinePlaceholder = require('multiline-placeholder/multiline-placeholder.js')

let default_text = Api.isDevServer() ? `2 gula lökar
100 gram quorn
100 gram nötkött
100 gram fågel
100 gram tofu
3 msk smör
50 g test
---
100 g fisk
100 g test2
` : ""

// default_text = ""

export default {
  name: 'home',
  components: { VSelect, Textarea },
  data() {
    return {
      portions_options: [{label: '1 portion', value: 1}, 
                         {label: '2 portioner', value: 2}, 
                         {label: '3 portioner', value: 3}, 
                         {label: '4 portioner', value: 4}, 
                         {label: '5 portioner', value: 5},
                         {label: '6 portioner', value: 6},
                         {label: '7 portioner', value: 7},
                         {label: '8 portioner', value: 8},
                         {label: '9 portioner', value: 9},
                         {label: '10 portioner', value: 10},
                         {label: '11 portioner', value: 11},
                         {label: '12 portioner', value: 12}
                         ],
      portion: {label: "4 portioner", value: 4},
      text: default_text,
      mode: "recipe",
      show_empty_input_error: false,
      prevRoute: null
    }
  },
  watch: {
    text: {
      handler() {
        localStorage.setItem('usertext', this.text);
      },
      deep: true,
    },
  },
  created() {
    // Api.getRecipesFromText(
    //   "1 dl test\n200 g smör\n---\n100 g grönsaker", 
    //   4,
    //   (values) => {console.log("it's the done", values)},
    //   (err) => {console.log("failed", err)},
    //   )
    // on back/forward
    // console.log("prev", VueCookies.get("prevRoute"))

    if(window.popStateDetected && VueCookies.get("prevRoute") == "recipe") {
      this.text = localStorage.getItem('usertext')
      // console.log("user text")
    }
    else {
      localStorage.setItem("usertext", "")
      // console.log("emptied text")
    }
    // var that = this
    // window.onpopstate = function(event) {
    //   console.log("pooop location: " + document.location + ", state: " + JSON.stringify(event.state) + ", data: " + localStorage.getItem('usertext'));
    //   that.text = localStorage.getItem('usertext')
    //   console.log("that", that)
    // }
  },
  // computed: {
  //   recipe_text: function () {
  //     return "Mitt recept\n" + this.portion.value + " portioner\n" + this.text
  //   }
  // },
  // created: {
  //   console.log("creay")
  // },
  methods: {
    text_is_link: function(text) {
      if(text.startsWith("http://") ||
        text.startsWith("https://") ||
        text.startsWith("www.") ||
        text.startsWith("coop.") ||
        text.startsWith("koket.se") ||
        text.startsWith("mittkok.expressen.se") ||
        text.startsWith("ica.")) {
          return true
        }
        else {
          return false
        }
    },
    goto_about: function() {
      this.$router.push({ path: 'about'})
    },
    goto_recipe: function() {
      if(this.text_is_link(this.text)) {
          this.$router.push({ path: 'recipe', query: { url: this.text }})
        }
        else if(this.text) {
          this.$router.push({ path: 'recipe', query: { text: this.text, portions: this.portion.value }})
        }
        else {
          // do something to inform user of needing to input
        }
    },
    next_step: function() {
      // router-link :to="{ path: 'recipe', query: { text: recipe_text } }"
      if(this.mode == "recipe" && this.text && !this.text_is_link(this.text)) {
        this.mode = "portions"
      }
      else if(this.mode == "portions" || (this.mode == "recipe" && this.text_is_link(this.text))) {
        this.goto_recipe()
      }
      else {
        this.show_empty_input_error = true
      }
    }
  }
}
</script>
