<template>
  <div id="app" :class="{'app-margin-bottom': currentRoute() != 'home'}">
    <div id="minimal_header" v-if="currentRoute() == 'manual'"></div>
    <!-- <div id="header" v-if="currentRoute() != 'manual'">
     <input v-model="url" :placeholder="placeholder_url" id="search_field" v-on:keyup.enter="search()" autofocus>
     <router-link :to="{ path: 'recipe', query: { url: url} }" id="search_button">
       <img src="./assets/search-icon.svg" height="37">
     </router-link>
    </div> -->
    <router-view/>
    <div id="footer" v-if="['about'].indexOf(currentRoute()) != '-1'">
      <router-link :to="{ path: '/'}">Tillbaka</router-link>
    </div>
  </div>
</template>

<script>
import Api from '@/utils/Api.js'
// import VueCookies from 'vue-cookies'

// import Vue from 'vue'

let url_default = Api.isDevServer() ? 'https://smasteg.nu/test/testrecept-coop.html' : ''

// Vue.prototype.window = window

export default {
  data() {
      return {
        url: url_default
      }
    },
  computed: {
    placeholder_url() {
        return "Klistra in receptlänk här"
    },
    test() {
      return this.$router.currentRoute.query.url
    }
  },
  // watch: {
  //     $router: {
  //           handler() {console.log("changed")},
  //           deep: true // <-- Not really required
  //     }
  // },
  // created() {
  //   console.log("reading", VueCookies.get('prevRoute'))
  //   window.prevRoute = VueCookies.get('prevRoute')
  // },
  // created() {
  //   // on back/forward
  //   window.onpopstate = function(event) {
  //     alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
  //   }
  // },
  
  // beforeRouteLeave(to,from,next) {
  //   // console.log("destroy", this.$router.currentRoute.name)
  //   // VueCookies.set('prevRoute',this.$router.currentRoute.name)
  //   console.log("destroy", from.name)
  //   VueCookies.set('prevRoute',from.name)
  //   next()
  // },
  methods: {
    // search() {
    //   this.$router.push({ path: 'recipe', query: { url: this.url }})
    // },
    currentRoute() {
      return this.$router.currentRoute.name
    }
    // ,
    // urlFromQuery() {
    //   let url = this.$router.currentRoute.query.url
    //   return url ? url : 'https://smasteg.nu/test/testrecept-coop.html'
    // }
  }
}
</script>

<style lang="scss">
@import './assets/css/variables.scss';
@import './assets/css/app.scss';
@import url('https://use.fontawesome.com/releases/v5.5.0/css/all.css');

@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=4e207fde-0c48-4131-93cf-f630c5346483");
@font-face{
    font-family:"Avenir Next W01 Thin";
    src:url("./assets/Fonts/42c793cf-df5a-4e07-bf3e-37de54df02b6.eot?#iefix");
    src:url("./assets/Fonts/42c793cf-df5a-4e07-bf3e-37de54df02b6.eot?#iefix") format("eot"),url("./assets/Fonts/64779e28-a3b8-4364-949c-771372a0a327.woff2") format("woff2"),url("./assets/Fonts/c6c8e4be-17eb-4475-bbfc-bb485ffde766.woff") format("woff"),url("./assets/Fonts/87a872d0-5af3-4231-a77c-37f41ec74662.ttf") format("truetype");
}
@font-face{
    font-family:"Avenir Next W01 Light";
    src:url("./assets/Fonts/0ecef829-586e-426d-a633-4c8a8c926769.eot?#iefix");
    src:url("./assets/Fonts/0ecef829-586e-426d-a633-4c8a8c926769.eot?#iefix") format("eot"),url("./assets/Fonts/49c5f3e1-5867-4b1d-a843-2d07fa60d85d.woff2") format("woff2"),url("./assets/Fonts/f401df6f-54eb-406c-b46a-90b9451c598d.woff") format("woff"),url("./assets/Fonts/276fe25e-e430-4376-b33c-705dd77b3dba.ttf") format("truetype");
}
@font-face{
    font-family:"AvenirNextLTW01-Regular";
    src:url("./assets/Fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix");
    src:url("./assets/Fonts/e9167238-3b3f-4813-a04a-a384394eed42.eot?#iefix") format("eot"),url("./assets/Fonts/2cd55546-ec00-4af9-aeca-4a3cd186da53.woff2") format("woff2"),url("./assets/Fonts/1e9892c0-6927-4412-9874-1b82801ba47a.woff") format("woff"),url("./assets/Fonts/46cf1067-688d-4aab-b0f7-bd942af6efd8.ttf") format("truetype");
}
@font-face{
    font-family:"AvenirNextLTW01-Medium";
    src:url("./assets/Fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix");
    src:url("./assets/Fonts/1a7c9181-cd24-4943-a9d9-d033189524e0.eot?#iefix") format("eot"),url("./assets/Fonts/627fbb5a-3bae-4cd9-b617-2f923e29d55e.woff2") format("woff2"),url("./assets/Fonts/f26faddb-86cc-4477-a253-1e1287684336.woff") format("woff"),url("./assets/Fonts/63a74598-733c-4d0c-bd91-b01bffcd6e69.ttf") format("truetype");
}
@font-face{
    font-family:"Avenir Next LT W01 Demi";
    src:url("./assets/Fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix");
    src:url("./assets/Fonts/12d643f2-3899-49d5-a85b-ff430f5fad15.eot?#iefix") format("eot"),url("./assets/Fonts/aad99a1f-7917-4dd6-bbb5-b07cedbff64f.woff2") format("woff2"),url("./assets/Fonts/91b50bbb-9aa1-4d54-9159-ec6f19d14a7c.woff") format("woff"),url("./assets/Fonts/a0f4c2f9-8a42-4786-ad00-fce42b57b148.ttf") format("truetype");
}
@font-face{
    font-family:"Avenir Next LT W01 Bold";
    src:url("./assets/Fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix");
    src:url("./assets/Fonts/dccb10af-07a2-404c-bfc7-7750e2716bc1.eot?#iefix") format("eot"),url("./assets/Fonts/14c73713-e4df-4dba-933b-057feeac8dd1.woff2") format("woff2"),url("./assets/Fonts/b8e906a1-f5e8-4bf1-8e80-82c646ca4d5f.woff") format("woff"),url("./assets/Fonts/890bd988-5306-43ff-bd4b-922bc5ebdeb4.ttf") format("truetype");
}
@font-face{
    font-family:"AvenirNextLTW01-Heavy";
    src:url("./assets/Fonts/3418f6be-70a5-4c26-af1d-c09a8642ca20.eot?#iefix");
    src:url("./assets/Fonts/3418f6be-70a5-4c26-af1d-c09a8642ca20.eot?#iefix") format("eot"),url("./assets/Fonts/5c57b2e2-f641-421e-a95f-65fcb47e409a.woff2") format("woff2"),url("./assets/Fonts/181c847e-cdbc-43d5-ae14-03a81c8953b4.woff") format("woff"),url("./assets/Fonts/045d1654-97f2-4ff0-9d24-21ba9dfee219.ttf") format("truetype");
}

</style>
