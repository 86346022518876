var dev_url = "http://localhost:8889"
var prod_url = "https://receptkoll-backend.smasteg.nu"

var base_url = window.location.href.substring(0,17) == "http://localhost:" ? dev_url : prod_url

export default {
    isDevServer()
    {
        return base_url == dev_url
    },
    saveUserData(recipe_id, ingredient_id, food_item_id, category_id, total_gram, callback)
    {
        var err = null
        this.post(base_url + "/user-data-save", {"recipe_id": recipe_id, "ingredient_id": ingredient_id, "food_item_id": food_item_id, "category_id": category_id, "amount_string": total_gram}, function(response) {
            var obj = JSON.parse(response.responseText)
            if(typeof obj.status == "string" && obj.status == "error") {
                err = this.getUserError(obj.error_message)
            }
            callback(err, obj)
        }.bind(this))
    },
    getRecipeFromUrl(url, callback)
    {
        var err = null
        // console.log("from url")
        this.post(base_url + "/recipe-from-url", {"url": url}, function(response) {
            // console.log("RESULT:" + response.responseText)
            var obj = JSON.parse(response.responseText)
            if(typeof obj.status == "string" && obj.status == "error") {
                err = this.getUserError(obj.error_message)
            }
            callback(err, obj)
        }.bind(this))
    },
    getUserError(err) {
        if(err == "Website not supported") {
            return "Webbsidan stöds inte ännu. Men ett meddelande har skickats till vi som byggt appen om att du vill kunna analysera recept från denna sida.";
        }
        else if(err.substring(0, 22) == "Failed parsing recipe:") {
            return "Vi kunde inte hitta receptet i länken du skickade. Administratör har meddelats."
        }
        else {
            return err;
        }
    },
    getOneRecipeFromText(text, successCallback, failCallback)
    {
        // console.log("from the text")
        var err = null
        this.post(base_url + "/recipe-from-text", {"text": text}, function(response) {

            var obj = JSON.parse(response.responseText)
            if(typeof obj.status == "string" && obj.status == "error") {
                err = this.getUserError(obj.error_message)
                failCallback(err)
            }

            successCallback(JSON.parse(response.responseText))
        }.bind(this))
    },
    getSuggestionsFromCategories(ingredients, categories, grams, callback)
    {
        // var err = null
        this.post(base_url + "/suggestions-from-categories", {"ingredients": ingredients.join(","), "categories": categories.join(","), "grams": grams.join(",")}, function(response) {
            
            // console.log("response", ingredients, response)
            var obj = JSON.parse(response.responseText)
            // console.log("response", obj)
            // var obj = JSON.parse(response.responseText)
            // if(typeof obj.status == "string" && obj.status == "error") {
            //     err = this.getUserError(obj.error_message)
            // }

            callback(obj)
        }.bind(this))
    },
    getRecipesFromText(text, portions, successCallback, failCallback) {
        let recipes = text.split("---")
        // console.log("portions", portions)

        recipes = recipes.filter(recipe => recipe.trim().length > 0)
        recipes = recipes.map(function(x) {
                x = x.trim()
                return "Mitt recept\n" + portions + " portioner\n" + x
            })

        // console.log("recipes", recipes)
        let recipe_promises = []

        recipes.forEach(function(recipe) {
            let promise = new Promise(
                function(resolve, reject) {
                    // console.log("starting promise for", recipe)
                    // setTimeout(resolve, 100, 'foo')
                    this.getOneRecipeFromText(recipe, resolve, reject)
                }.bind(this)
            )

            recipe_promises.push(promise)
        }.bind(this))

        Promise.all(recipe_promises).then(function(values) {
            successCallback(values)
        }).catch(function(data) {
            failCallback(data)
        })
    },
    post(url, postData, callback) {
        var async = true;

        var xhttp = new XMLHttpRequest();

        xhttp.onreadystatechange = function() {
            if (xhttp.readyState == 4) {
                callback(this)
            }
        };
        xhttp.open("POST", url, async);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhttp.send(this.serialize(postData));
    },
    serialize(obj) {
        var str = [];
        for (var p in obj)
          if (Object.prototype.hasOwnProperty.call(obj, p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
    }
}